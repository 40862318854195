import * as React from "react"

import { globalHistory, Link } from '@reach/router'
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import Seo from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionMoreLink} from "@layouts/Section"
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"
import { TransitionLink } from "@atoms/Link"
// import { logoutHandler} from "@utility/UrlData"
// import * as css from "@css/layouts/l-error.module.styl"

function Error404Page(){
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    const { location } = globalHistory

    // if (typeof window !== 'undefined' && !location.pathname.match(/404/g)) {
    //     window.location = '/404.html';
    // }

    return(
        <>
            <Seo subtitle="500 Error." />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="500 Error"
                    text="エラ-"
                />
                <SectionContent isBorder={true}>
                    <section>
                        <p>
                        当ウェブサイトにて問題が発生している可能性があります。以下の対応により、問題が解決する場合があります。<br/>
                        ・ブラウザのキャッシュをクリア。<br/>
                        ・ファンクラブから<TransitionLink to={process.env.GATSBY_MODD_URL_LOGOUT}>ログアウト</TransitionLink>してから再度ログイン。<br/>
                        それでも問題が解決されない場合は、<TransitionLink to={process.env.GATSBY_MODD_URL_MYPAGE}>マイページ</TransitionLink>から会員情報をご確認いただけますようお願いいたします。
                        </p>
                    </section>
                </SectionContent>
                <SectionMoreLink
                    title="Top"
                    text="トップへ"
                    to={userLogin ? "/member/" : "/"}
                />
            </Section>
        </>
    )
}

export default Error404Page
